<template>
  <!-- 登陆页 -->
  <div class="login_contain">
    <div class="login_box">
      <div class="login_logo"></div>
      <div class="login_tit">消防物联网监控云平台后台管理</div>
      <div class="login_form">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
          <el-form-item prop="name">
            <el-input type="text" class="name" placeholder="请输入账号" auto-complete="off" v-model="loginForm.username" @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" class="password" placeholder="请输入密码" auto-complete="off" v-model="loginForm.password" @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="loginBtn" @click.native.prevent="submitForm">立即登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin } from '@/api/user.js'
const SM3 = require('sm-crypto')
export default {
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { min: 6, max: 18, message: '密码长度在 6 到 18 个字符', trigger: 'blur'}
        ]
      },
    }
  },
  created () {
    window.localStorage.setItem('token', 'Bonfire token')
  },
  methods: {
    submitForm () {
      this.$refs.loginForm.validate(valid => {
        if (!valid) return
        const password = SM3.sm3(this.loginForm.password)
        const params = {
          account: this.loginForm.username,
          password: password
        }
        userLogin(params).then(res => {
          if (res.status === '1') {
            window.localStorage.setItem('token', 'Bonfire ' + res.data.usertoken)
            window.localStorage.setItem('loginInfo', JSON.stringify(res.data))
            this.$router.push({ path: '/Home' })
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.login_contain {
  width: 100%;
  height: 100%;
  position: relative;
  background: url(../../assets/img/logbg.png) no-repeat;
  background-size: 100% 100%;
}
.login_box {
  width: 460px;
  height: 700px;
  position: absolute;
  right: 120px;
  top: 0px;
  background: url(../../assets/img/logrg.png) no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.login_logo {
  width: 210px;
  height: 150px;
  position: relative;
  margin: 50px auto 0;
  background: url(../../assets/img/log.png) no-repeat;
  background-size: 100% 100%;
}
.login_tit {
  font-size: 24px;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  margin: 40px auto 0;
}
.login_form {
  position: relative;
  margin: 40px auto 0;
  padding: 0 50px;
  box-sizing: border-box;
}
.name >>> .el-input__inner, .password >>> .el-input__inner, .loginBtn {
  width: 360px;
  height: 60px;
  background: rgba(31, 67, 126, 0.5);
  border-radius: 10px;
  border: none;
  backdrop-filter: saturate(100%) blur(50px);
  padding: 0 40px;
  box-sizing: border-box;
  color: #A4AFC8;
}
.password >>> .el-input__inner {
  margin-top: 30px;
}
.name ::placeholder, .password ::placeholder{
  font-size: 16px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 23px;
  color: #A4AFC8;
}
.loginBtn {
  margin-top: 60px;
  font-size: 16px;
  font-family: 'PingFang SC';
  font-weight: 400;
  line-height: 23px;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
